
@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url('../../assets/fonts/Roboto/Roboto-Bold.ttf') format('opentype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    src: url('../../assets/fonts/Roboto/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf') format('opentype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    src: url('../../assets/fonts/Roboto/Roboto-Light.ttf') format('opentype');
}
