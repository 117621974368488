@import "../../style/utils/vars";
@import "../../style/utils/mixins";
@import "../../style/utils/media";

.login{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    padding-bottom: 55px;
    @include mobile{
        width: 80%;
    }
    .logo{
        display: flex;
        align-items: center;
        img{
            height: 40px;
            margin-bottom: 20px;
        }
    }

    .btn{
        width: 100%;
        line-height: 26px;
        height: 36px;
        border-radius: 18px;
    }
    input{
        width: 100%;
        box-sizing: border-box;
        line-height: 30px;
        margin: auto;
        padding-left: 10px;
        font-size: 16px;
        border: 2px solid black;
        border-radius: 18px;
        margin-bottom: 20px;
        outline: none;
    }
    .error{
        font-size: 16px;
        text-align: left;
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-weight: 600;
    }
    .wrapper{
        position: relative;
        label{
            position: absolute;
            top: -8px;
            font-weight: 600;
            font-size: 16px;
            left: 18px;
            background: #fff;
            padding: 0 5px;
            span{
                color: $error;
            }
        }
    }
}
