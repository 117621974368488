@import "utils/vars";

.react-calendar{
  border: none;
  font-family: 'Roboto', sans-serif;
}
.react-calendar__tile--active{
  background: $primary2!important;
  border-radius: 4px;
}
.react-calendar__tile--now{
  background: rgba($primary2, .2);
  border-radius: 4px;
}
