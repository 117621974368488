

@mixin mobile{
    @media (max-width: 600px) {
        @content
    }
}

@mixin tablet-portrait{
    @media (max-width: 900px) {
        @content
    }
}
@mixin tablet-landscape{
    @media (max-width: 1200px) {
        @content
    }
}
