@import "../../../style/utils/vars";

.select {
    width: 100%;
    position: relative;
    line-height: 24px;
    height: 24px;
    background: #ddd;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    * {
        outline: none;
    }
    .label {
        position: absolute;
        left: 0;
        top: -24px;
        display: block;
        font-size: 14px;
        color: $grey3;
    }
    .error{
        position: absolute;
        left: 0;
        top: -24px;
        display: block;
        font-size: 14px;
        color: $primary2;
    }
    .placeholder {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        box-sizing: border-box;
        span {
            color: #000;
            font-weight: 300;
        }
    }
    .value {
        font-weight: 500;
        color: #000;
        padding-left: 5px;
    }
    .chevron {
        width: 14px;
        height: 14px;
        fill: #000;
        position: absolute;
        z-index: 13;
        right: 8px;
        top: 6px;
    }

    .options {
        background: #ddd;
        position: absolute;
        z-index: 12;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        overflow: hidden;
        max-height: 80vh;
        overflow-y: auto;
    }
    .item {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        height: 24px;
        user-select: none;
        padding-left: 5px;
        box-sizing: border-box;
        color: #000;
        &:hover {
            background: lighten($primary2, 10%);
        }
    }
}