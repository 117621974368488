$error: #e93000;
$warning: #ffb725;

$primary1: #e23716;
$primary2: #e22020;
$primary3: #c62121;

$secondary1: #666;
$secondary2: #444;
$secondary3: #222;

$grey1: #f8f8f8;
$grey2: #d2d2d2;
$grey3:#888;
$grey4: #2E2E2E;
